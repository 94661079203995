@use '@queries' as *;
@use '@typography' as *;
@use '@fixed-size' as *;
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  @include fixedSize(max-content, 30px);
  border-radius: var(--cornerradius8);
  @include min-1920-break {
    padding: 6px 8px;
  }
  .timer {
    @include weight-medium;
    @include typography-s;
  }
}

.variant {
  &-success {
    p {
      color: var(--cst-additional-info-title-green);
    }
    background: var(--cst-additional-info-background-green);
  }

  &-warn {
    p {
      color: var(--cst-additional-info-title-orange);
    }

    background: var(--cst-additional-info-background-orange);
  }

  &-danger {
    p {
      color: var(--cst-additional-info-title-red);
    }

    background: var(--cst-additional-info-background-red);
  }
}
