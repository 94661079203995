@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @include singleFixedSize(120px);

  @include min-428-break {
    @include singleFixedSize(160px);
  }

  @include min-1024-break {
    @include singleFixedSize(120px);
  }

  @include min-1440-break {
    @include singleFixedSize(160px);
  }

  .vectors-container {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: rotating 2s linear infinite;

    position: relative;

    .big-vector {
      @include singleFixedSize(120px);

      @include min-428-break {
        @include singleFixedSize(160px);
      }

      @include min-1024-break {
        @include singleFixedSize(120px);
      }

      @include min-1440-break {
        @include singleFixedSize(160px);
      }
    }

    .small-vector-container {
      animation: rotating-clockwise 10s linear infinite;
      scale: 1.1;
      position: absolute;
      border-radius: 50%;

      @include singleFixedSize(90px);

      @include min-428-break {
        @include singleFixedSize(120px);
      }

      @include min-1024-break {
        @include singleFixedSize(90px);
      }

      @include min-1440-break {
        @include singleFixedSize(120px);
      }

      .small-vector {
        @include singleFixedSize(inherit);
      }
    }
  }

  .timer-text {
    @include typography-m;
    @include weight-bold;

    @include min-428-break {
      @include typography-xl;
    }

    @include min-1024-break {
      @include typography-m;
    }

    @include min-1440-break {
      @include typography-xl;
    }
  }
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating-clockwise {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
