@use '@queries' as *;
@use '@fixed-size' as *;

.wrapper {
  position: relative;
  overflow: hidden;
  @include singleFixedSize(34px);
  @include min-428-break {
    @include singleFixedSize(38px);
  }
  @include min-744-break {
    @include fixedSize(158px, 38px);
  }
  @include min-1024-break {
    @include fixedSize(112px, 27px);
  }
  @include min-1440-break {
    @include fixedSize(150px, 36px);
  }
}

.container {
  position: relative;
  cursor: pointer;

  @include fixedSize(141px, 34px);
  @include min-428-break {
    @include fixedSize(158px, 38px);
  }
  @include min-1024-break {
    @include fixedSize(112px, 27px);
  }
  @include min-1440-break {
    @include fixedSize(150px, 36px);
  }
}
