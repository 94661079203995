.wrapper {
  display: flex;
  fill: inherit;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor !important;
  }
}
.illustration {
  display: inline-block;
  width: 100%;
  height: 100%;
}
