@use '@/app/queries' as *;
@use '@typography' as *;
@use '@fixed-size' as *;

.label {
  display: flex;
  gap: 8px;
  align-items: center;
  width: max-content;
}

.variant {
  &--primary {
    span.text {
      color: var(--cst-label-text-default);
    }
  }

  &--secondary {
    span.text {
      color: var(--cst-label-secondary-text-default);
    }

    &:hover {
      span.text {
        color: var(--cst-label-secondary-text-hover);
      }
    }

    &:active {
      span.text {
        color: var(--cst-label-secondary-text-pressed);
      }
    }
  }
}

.size {
  font-family: Inter, sans-serif;
  span.text {
    @include typography-s;
    @include weight-semi-bold;
  }

  @include min-428-break {
    gap: 8px;
    span.text {
      @include typography-m;
    }
  }

  @include min-1024-break {
    gap: 4px;
    span.text {
      @include typography-m;
    }
  }

  @include min-1440-break {
    gap: 6px;
    span.text {
      @include typography-l;
    }
  }

  @include min-1920-break {
    span.text {
      @include typography-xl;
    }
  }
}

.label-decoration__container {
  position: relative;
}

.label-decoration {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);

  width: 70px;
  height: 21px;

  @include fixedSize(70px, 21px);

  @include min-1024-break {
    @include fixedSize(70px, 24px);
  }

  @include min-1440-break {
    @include fixedSize(80px, 26px);
  }

  @include min-1920-break {
    @include fixedSize(112px, 34px);
  }
}

.label-icon {
  @include singleFixedSize(22px);
  display: flex;
  justify-content: center;
  align-items: center;

  @include min-1024-break {
    @include singleFixedSize(22px);
  }

  @include min-1440-break {
    @include singleFixedSize(24px);
  }

  @include min-1920-break {
    @include singleFixedSize(26px);
  }
}
