@use '@typography' as *;
@use '@queries' as *;
@use '@button' as *;
@use '@fixed-size' as *;

.modal {
  border-radius: 16px;
  border: 4px solid var(--cst-authorization-outline);
  height: max-content;
  padding: 0;
}

.sheet-title {
  color: #303030;
  @include weight-semi-bold;
  @include typography-base;

  @include min-428-break {
    @include typography-l;
  }
}

.sheet-content {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 12px;
  padding: 14px;

  @include min-428-break {
    gap: 14px;
  }

  @include min-1024-break {
    gap: 5px;
    padding: 5px;
  }

  @include min-1440-break {
    gap: 10px;
    padding: 10px;
  }
}

.button {
  @include buttonSizes('38', '46', '46', '42', '50', '50');

  border-radius: var(--cornerradius8);

  @include min-428-break {
    border-radius: var(--cornerradius10);
  }
  @include min-744-break {
    border-radius: var(--cornerradius12);
  }

  span[id='btn-icon'],
  svg {
    @include singleFixedSize(18px);

    @include min-428-break {
      @include singleFixedSize(20px);
    }
  }
}
