@use '../../../app/queries' as *;
@use '@fixed-size' as *;

.size {
  @include singleFixedSize(14px);

  &--18 {
    @include singleFixedSize(18px);
  }

  &--14 {
    @include singleFixedSize(14px);
  }

  @include min-1440-break {
    @include singleFixedSize(18px);
  }
}
