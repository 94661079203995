@use '@queries' as *;

.container {
  display: flex;
  width: 100%;
  height: 3px;
  border-radius: 4px;
  overflow: hidden;
  flex-direction: row-reverse;

  @include min-1024-break {
    min-height: 4px;
  }

  &--progressbar {
    display: flex;
    height: inherit;
    width: 0%;
    border-radius: 8px;
    transition: width 0.3s ease;
    animation: colorChange calc(var(--delay) * 1s) linear;
    animation-iteration-count: 1;

    &-static {
      width: calc(var(--width) * 1%) !important;
      animation: none;
    }
  }
}

@keyframes colorChange {
  0% {
    width: 100%;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 0%;
  }
}

.variant {
  &--success {
    background: var(--cst-progress-indicator-background-successfully);
    .container {
      &--progressbar,
      &--progressbar-static {
        background: var(--cst-progress-indicator-indicator-successfully);
      }
    }
  }

  &--warn {
    background: var(--cst-progress-indicator-background-warn);
    .container {
      &--progressbar,
      &--progressbar-static {
        background: var(--cst-progress-indicator-indicator-warn);
      }
    }
  }

  &--error {
    background: var(--cst-progress-indicator-background-error);
    .container {
      &--progressbar,
      &--progressbar-static {
        background: var(--cst-progress-indicator-indicator-error);
      }
    }
  }

  &--balance {
    background: var(--cst-progress-indicator-background-balance);
    .container {
      &--progressbar,
      &--progressbar-static {
        background: var(--cst-progress-indicator-indicator-balance);
      }
    }
  }
}

.fill {
  &--left {
    flex-direction: row;
  }

  &--right {
    flex-direction: row-reverse;
  }
}
