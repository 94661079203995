@use '@fixed-size' as *;
@use '@typography' as *;
@use '@content' as *;
@use '@tabs' as *;
@use '@button' as *;
@use '@queries' as *;

.egg-counter {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 10.5px 12px;
  @include fixedSize(62px, 42px);
  border-radius: var(--cornerradius8);
  background: var(--cst-counter-background);

  @include min-428-break {
    @include fixedSize(68px, 46px);
    border-radius: var(--cornerradius10);
    padding: 11px 12px;
    gap: 10px;
  }

  @include min-744-break {
    @include fixedSize(74px, 52px);
    border-radius: var(--cornerradius12);
    padding: 14px;
    gap: 8px;
  }

  @include min-1024-break {
    padding: 10.5px 10px;
    @include fixedSize(62px, 42px);
    gap: 6px;
  }

  @include min-1440-break {
    padding: 13.5px 12px;
    @include fixedSize(75px, 54px);
    gap: 6px;
  }

  &--icon {
    color: var(--cst-counter-icon);
    @include singleFixedSize(16px);

    @include min-428-break {
      @include singleFixedSize(18px);
    }

    @include min-744-break {
      @include singleFixedSize(20px);
    }

    @include min-1440-break {
      @include singleFixedSize(24px);
    }
  }

  &--text {
    @include typography-base;
    @include weight-medium;
    color: var(--cst-counter-label);

    @include min-428-break {
      @include typography-m;
    }

    @include min-1024-break {
      @include typography-base;
    }

    @include min-1440-break {
      @include typography-l;
    }
  }
}
