.container {
  display: flex;
  border-radius: 2px;
}

.direction {
  &--vertical {
    flex-direction: column;
    width: 1px;
    min-width: 1px;
    background: var(--cst-dividers-vertical);
    height: inherit;
    min-height: inherit;
  }

  &--horizontal {
    flex-direction: row;
    height: 1px;
    width: 100%;
    background: var(--cst-dividers-horizontal);
  }
}
