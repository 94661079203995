@use '@/shared/ui/button/adaptive' as *;
@use '../../styles/fixed' as *;
@use '../typography/sizes' as *;
@use '../../../app/queries' as *;

.container {
  width: 100%;
  height: max-content;
  background-color: #fafafa;
  box-shadow: inset 0 0 0 2px #f6f6f6;
  border-radius: 10px;
  padding: 10px;
  @include min-428-break {
    padding: 12px;
  }
  @include min-1024-break {
    padding: 14px;
  }
}

.text {
  color: #646464;
  @include typography-s;
  @include weight-medium;
  @include min-428-break {
    @include typography-base;
  }
  @include min-1024-break {
    @include typography-s;
  }
  @include min-1440-break {
    @include typography-base;
  }
}
