@use '@fixed-size' as *;

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 8px;
  border-radius: 6px;
  background: var(--cst-tag-quantity-background-active);

  &--default {
    background: var(--cst-tag-quantity-background-default);
  }
  &--squared {
    @include singleFixedSize(24px);
  }
}

.quantity-value {
  color: var(--cst-tag-quantity-text-active);

  &--none {
    color: var(--cst-tag-quantity-text-default);
  }
}
