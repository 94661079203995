@use '@typography' as *;
@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.container {
  display: flex;
  padding: 14px;
  flex: 1;
  min-height: 100%;

  @include min-744-break {
    padding: 0px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 100%;
    min-height: 100%;
    flex: 1;
    padding-top: 18px;
    align-items: center;
    position: relative;

    .top-right-slot {
      position: absolute;
      top: 0;
      right: 0;

      @include min-744-break {
        top: 14px;
        right: 14px;
      }

      @include min-1024-break {
        top: 20px;
        right: 19px;
      }

      @include min-1440-break {
        top: 30px;
        right: 30px;
      }
    }

    @include min-428-break {
      gap: 22px;
      padding-top: 68px;
    }

    @include min-744-break {
      padding: 60px 14px 14px 14px;
    }

    @include min-1024-break {
      gap: 14px;
      padding: 24px;
      min-height: max-content;
    }

    @include min-1440-break {
      gap: 28px;
      padding: 30px;
    }
  }
}

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  &--label {
    span[id='label-text'] {
      @include typography-base;
      @include weight-semi-bold;

      @include min-1440-break {
        @include typography-m;
      }
    }
  }

  &--text {
    color: var(--cst-offer-text);
    @include typography-base;
    @include weight-semi-bold;

    @include min-1440-break {
      @include typography-m;
    }
  }
}

.button {
  @include fixedSize(100%, 38px);

  span[id='button-span-text'] {
    @include typography-s;
    @include weight-semi-bold;

    @include min-428-break {
      @include typography-base;
    }
  }

  @include min-428-break {
    @include fixedSize(100%, 46px);
  }

  @include min-1024-break {
    @include fixedSize(100%, 42px);
  }

  @include min-1440-break {
    @include fixedSize(100%, 50px);
  }
}

.body {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  gap: 14px;

  @include min-1024-break {
    gap: 8px;
  }

  @include min-1440-break {
    gap: 10px;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  position: relative;
  margin-top: auto;
  justify-self: flex-end;

  &.full-h {
    flex: 1;
    justify-content: space-between;
  }

  @include min-428-break {
    gap: 10px;
  }

  @include min-1024-break {
    gap: 8px;
  }

  @include min-1440-break {
    gap: 10px;
  }
}

.title-block {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
  justify-content: center;

  .title {
    @include typography-m;
    @include weight-semi-bold;
    color: var(--alias-modal-on-primary-text-primary);

    @include min-428-break {
      @include typography-xl;
    }

    @include min-1024-break {
      @include typography-m;
    }

    @include min-1440-break {
      @include typography-xl;
    }
  }

  .subtitle {
    @include typography-s;
    @include weight-medium;
    color: var(--alias-modal-on-primary-text-secondary);

    @include min-428-break {
      @include typography-base;
    }

    @include min-1024-break {
      @include typography-s;
    }

    @include min-1440-break {
      @include typography-base;
    }
  }
}

.close-button {
  @include singleFixedSize(38px);
  @include min-428-break {
    @include singleFixedSize(46px);
  }
  @include min-1024-break {
    @include singleFixedSize(36px);
    border-radius: var(--cornerradius6);
  }
  @include min-1440-break {
    @include singleFixedSize(46px);
    border-radius: var(--cornerradius10);
  }
}

.cross {
  color: #bfbfbf;
  @include singleFixedSize(18px);

  @include min-428-break {
    @include singleFixedSize(20px);
  }

  @include min-1024-break {
    color: #9e9d9e;
  }
}
