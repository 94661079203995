@use '@fixed-size' as *;
@use '@queries' as *;

.illustration {
  position: relative !important;
  overflow: hidden;
  align-self: center;
}

.variant {
  &--select-account,
  &--purchase-is-over,
  &--confirm-item-delete,
  &--shop-no-items,
  &--password-recovery,
  &--password-changed,
  &--sign-in,
  &--sign-up,
  &--error,
  &--success,
  &--promo {
    border-radius: var(--cornerradius10);
    @include fixedSize(196px, 140px);

    @include min-428-break {
      @include fixedSize(282px, 202px);
      border-radius: var(--cornerradius12);
    }

    @include min-1024-break {
      @include fixedSize(200px, 144px);
      border-radius: var(--cornerradius10);
    }
    @include min-1440-break {
      @include fixedSize(268px, 192px);
    }
  }
  &--same-items-not-found,
  &--history-no-items,
  &--cart-no-items,
  &--favorite-no-items,
  &--inventory-no-items {
    border-radius: var(--cornerradius10);
    @include fixedSize(242px, 160px);

    @include min-428-break {
      @include fixedSize(288px, 190px);
      border-radius: var(--cornerradius12);
    }

    @include min-1024-break {
      @include fixedSize(224px, 148px);
      border-radius: var(--cornerradius16);
    }
    @include min-1440-break {
      @include fixedSize(288px, 190px);
    }
  }

  &--404 {
    border-radius: var(--cornerradius10);
    @include fixedSize(242px, 160px);

    @include min-428-break {
      @include fixedSize(340px, 224px);
      border-radius: var(--cornerradius12);
    }

    @include min-744-break {
      @include fixedSize(444px, 290px);
      border-radius: var(--cornerradius18);
    }

    @include min-1024-break {
      @include fixedSize(306px, 200px);
      border-radius: var(--cornerradius14);
    }
    @include min-1440-break {
      @include fixedSize(368px, 240px);
      border-radius: var(--cornerradius16);
    }

    @include min-1920-break {
      @include fixedSize(430px, 280px);
      border-radius: var(--cornerradius18);
    }
  }

  &--add-friend,
  &--pick-up-items {
    border-radius: var(--cornerradius8);
    @include fixedSize(292px, 126px);

    @include min-428-break {
      @include fixedSize(400px, 176px);
    }

    @include min-1024-break {
      @include fixedSize(312px, 134px);
      border-radius: var(--cornerradius10);
    }
    @include min-1440-break {
      @include fixedSize(376px, 166px);
    }
  }
}
