@use '@queries' as *;
@use '@typography' as *;
@use '@fixed-size' as *;

.tag-info {
  display: flex;
  align-items: center;
  width: max-content;
  p {
    text-transform: capitalize;
  }
}

.variant {
  &--hot-price,
  &--discount {
    padding: 3.5px 6px;
    gap: 2px;
    border-radius: 6px;
    justify-content: center;

    p {
      color: var(--cst-tags-text-common);
      @include typography-xs;
      @include weight-semi-bold;
    }

    @include min-1440-break {
      padding: 3px 8px;
      gap: 2px;
      border-radius: 8px;
      justify-content: center;

      p {
        color: var(--cst-tags-text-common);
        @include typography-s;
        @include weight-medium;
      }
    }
  }

  &--hot-price {
    background: var(--cst-tags-background-hotprice);

    @include min-1440-break {
      min-width: 69px;
    }
  }

  &--hot-price {
    &.is-mm2 {
      background: var(--cst-tags-background-hotprice);
    }
  }

  &--info,
  &--gift,
  &--limited {
    background: #fbd8d8;
    width: 100%;
    height: 30px;
    padding: 6px 8px 6px 8px;
    gap: 8px;
    justify-content: center;
    border-radius: var(--cornerradius8);
    p {
      @include typography-s;
      @include weight-medium;
      color: var(--global-red600);
    }
    .icon-info {
      @include singleFixedSize(16px);
      color: #eb3c3c;
    }
  }

  &--available {
    background: #d9f1d1;

    @include singleFixedSize(30px);
    border-radius: var(--cornerradius8);
    display: flex;
    align-items: center;
    justify-content: center;
    .icon-gift-available {
      @include singleFixedSize(16px);
      color: #40bb18;
    }
  }

  &--discount {
    background: var(--cst-tags-background-discount);
    @include min-1440-break {
      min-width: 47px;
    }
  }

  &--discount {
    &.is-mm2 {
      background: #303030;
    }
  }
  &--discount-2 {
    padding: 3.5px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    box-shadow: inset 0 0 0 2px var(--cst-tags-outline-discountalternative);
    border-radius: var(--cornerradius6);
    background: var(--cst-tags-background-discountalternative);

    p {
      color: var(--cst-tags-text-discountalternative);
      @include typography-xs;
      @include weight-semi-bold;
    }

    @include min-428-break {
      padding: 4px 10px;
      p {
        @include typography-s;
        @include weight-semi-bold;
      }
    }

    @include min-1440-break {
      padding: 3.5px 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      box-shadow: inset 0 0 0 2px var(--cst-tags-outline-discountalternative);
      border-radius: 8px;
      background: var(--cst-tags-background-discountalternative);

      p {
        color: var(--cst-tags-text-discountalternative);
        @include typography-base;
        @include weight-semi-bold;
      }
    }
  }

  &--common,
  &--unique,
  &--ancient,
  &--vintage,
  &--godly,
  &--rare,
  &--uncommon,
  &--ultra_rare,
  &--legendary {
    justify-content: center;
    padding: 6.5px 8px;
    border-radius: var(--cornerradius6);
    gap: 6px;

    p {
      color: var(--cst-tags-text-common);
      @include typography-xs;
      @include weight-semi-bold;
    }

    @include min-1440-break {
      justify-content: center;
      padding: 7.5px 16px 7.5px 12px;
      border-radius: var(--cornerradius8);
      gap: 8px;

      p {
        color: var(--cst-tags-text-common);
        @include typography-base;
        @include weight-semi-bold;
      }
    }
  }

  &--unique {
    &.is-mm2 {
      background: var(--rarity-uncommon-background100);
    }
  }
  &--ancient {
    &.is-mm2 {
      background: #e0e345;
    }
  }
  &--vintage {
    &.is-mm2 {
      background: var(--rarity-ultra-rare-background100);
    }
  }
  &--godly {
    &.is-mm2 {
      background: #fe9920;
    }
  }

  &--common {
    background: var(--cst-tags-background-common);

    &.is-mm2 {
      background: var(--rarity-legendary-background100);
    }
  }

  &--rare {
    background: var(--cst-tags-background-rare);

    &.is-mm2 {
      background: var(--rarity-rare-background100);
    }
  }

  &--uncommon {
    background: var(--cst-tags-background-uncommon);

    &.is-mm2 {
      background: var(--rarity-common-background100);
    }
  }

  &--ultra_rare {
    background: var(--cst-tags-background-ultrarare);
  }

  &--legendary {
    background: var(--cst-tags-background-legendary);

    &.is-mm2 {
      background: #d41057;
    }
  }

  &--status,
  &--error,
  &--timer {
    padding: 2px 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    box-shadow: inset 0 0 0 2px var(--cst-tags-outline-discountalternative);
    border-radius: var(--cornerradius6);
    background: var(--cst-tags-background-discountalternative);

    p {
      color: var(--cst-tags-text-discountalternative);
      @include typography-xs;
      @include weight-medium;
    }

    @include min-428-break {
      padding: 4px 8px;
      p {
        @include typography-s;
      }
    }

    @include min-1024-break {
      padding: 2px 6px;
      p {
        @include typography-xs;
      }
    }

    @include min-1440-break {
      padding: 4px 8px;
      p {
        @include typography-s;
      }
    }
  }

  &--limited,
  &--gift {
    background: #ffebd2;
    width: 100%;
    p {
      color: #fe9920;
    }
    gap: 4px;
    .icon-gift {
      @include singleFixedSize(16px);
      color: #fe9920;
    }
  }

  &--gift {
    background: #fbd8d8;
    p {
      color: var(--global-red600);
    }
    .icon-gift {
      @include singleFixedSize(16px);
      color: #eb3c3c;
    }
  }

  &--timer,
  &--error {
    background: #e51a2e0d;
    box-shadow: inset 0 0 0 2px #e51a2e1a;
    p {
      color: var(--cst-diagramtooltip-line-primary);
    }
  }
  &--timer {
    gap: 4px;
    .icon-clock {
      @include singleFixedSize(12px);
      color: #eb3c3c;
    }
  }
}

.icon {
  @include singleFixedSize(14px);

  @include min-1440-break {
    @include singleFixedSize(18px);
  }
}
