@use '../../../app/queries' as *;
@use '../../styles/fixed' as *;

.item-rarity {
  @include singleFixedSize(260px);

  @include min-1440-break {
    @include singleFixedSize(386px);
  }
}

.item-rarity-mobile {
  @include fixedSize(100%, 100%);
}
